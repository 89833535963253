'use client';

import React, { CSSProperties, useState } from 'react';
import { LinkField } from '@vcc-www/content-management-jss-client';
import PopUpDisclaimerOverlay from './Overlay';
import { useKeyPress } from '@volvo-cars/react-utils';
import { Track } from '@volvo-cars/tracking';
import { Icon } from '@volvo-cars/react-icons';

export type PopUpDisclaimerContent = {
  title?: string;
  titleSource?: string;
  description: string;
  descriptionSource?: string;
  descriptionTitle?: string;
  descriptionTitleSource?: string;
  link?: LinkField;
  linkHrefSource?: string;
  linkTextSource?: string;
};

export type popUpDisclaimerProps = PopUpDisclaimerContent & {
  iconVerticalAlign?: CSSProperties['verticalAlign'];
  iconStyle?: string;
  overlayColorMode?: 'light' | 'dark';
  trackLabel?: string;
  addMarginInlineStart?: boolean;
};

const PopUpDisclaimer: React.FC<
  popUpDisclaimerProps & { children: (icon: JSX.Element) => React.ReactNode }
> = ({
  title,
  titleSource,
  description,
  descriptionSource,
  descriptionTitle,
  descriptionTitleSource,
  link,
  linkHrefSource,
  linkTextSource,
  iconVerticalAlign = 'baseline',
  iconStyle,
  overlayColorMode,
  trackLabel,
  addMarginInlineStart,
  children,
}) => {
  const [openOverlay, showDisclaimerOverlay] = useState(false);
  useKeyPress('Escape', () => showDisclaimerOverlay(false));
  const disclaimerIcon = (
    <Track
      eventLabel={trackLabel || `open PopUpDisclaimer | ${title}`}
      eventAction="image|click"
      ga3={{ eventAction: 'click' }}
    >
      <button
        onClick={() => showDisclaimerOverlay(true)}
        type="button"
        className={`${addMarginInlineStart ? 'ml-4' : ''} ${
          iconStyle ? iconStyle : ''
        }`}
        aria-label={`Click ${title} pop-up disclaimer info icon for more information`}
        style={{ verticalAlign: iconVerticalAlign }}
      >
        <Icon type="info-16" />
      </button>
    </Track>
  );

  return (
    <>
      {children(disclaimerIcon)}
      <PopUpDisclaimerOverlay
        title={title}
        titleSource={titleSource}
        description={description}
        descriptionSource={descriptionSource}
        descriptionTitle={descriptionTitle}
        descriptionTitleSource={descriptionTitleSource}
        isOpen={openOverlay}
        onClose={() => showDisclaimerOverlay(false)}
        link={link}
        linkHrefSource={linkHrefSource}
        linkTextSource={linkTextSource}
        overlayColorMode={overlayColorMode}
      />
    </>
  );
};

export default PopUpDisclaimer;
