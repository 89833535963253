import { ExtendCSS } from 'vcc-ui';

// Spacers are then added by addSpaceBlocks.ts
const resetMarginsStyle: ExtendCSS = {
  marginTop: '0 !important',
  marginBottom: '0 !important',
  '& + div': {
    marginTop: '0 !important',
  },
};

export default resetMarginsStyle;
