'use client';

import React, { MouseEventHandler, useEffect, useRef } from 'react';
import { LinkField } from '@vcc-www/content-management-jss-client';
import Markdown from '@vcc-www/markdown';
import { Track, useTracker } from '@volvo-cars/tracking';
import { IconButton } from '@volvo-cars/react-icons';
import styles from './pop-up-disclaimer.module.css';
import { useSharedComponentsTranslate } from '@vcc-www/shared-dictionaries/DictionariesProvider';

interface OverlayProps {
  description: string;
  descriptionSource?: string;
  descriptionTitle?: string;
  descriptionTitleSource?: string;
  onClose: () => void;
  isOpen: boolean;
  title?: string;
  titleSource?: string;
  link?: LinkField;
  linkHrefSource?: string;
  linkTextSource?: string;
  overlayColorMode?: 'light' | 'dark';
}

const PopUpDisclaimerOverlay: React.FC<
  React.PropsWithChildren<OverlayProps>
> = ({
  description,
  descriptionSource,
  descriptionTitle,
  descriptionTitleSource,
  onClose,
  isOpen,
  title,
  titleSource,
  link,
  linkHrefSource,
  linkTextSource,
  overlayColorMode,
}) => {
  const dialogRef = useRef<HTMLDialogElement>(null);
  const translate = useSharedComponentsTranslate();
  const ga3Tracker = useTracker({}, { mode: 'ga3', forceLowerCase: false });
  const tracker = useTracker({}, { mode: 'ga4' });

  useEffect(() => {
    if (dialogRef.current) {
      if (isOpen && !dialogRef.current.hasAttribute('open')) {
        dialogRef.current.showModal();
      } else if (!isOpen) {
        dialogRef.current.close?.();
      }
    }
  }, [isOpen]);

  const onBackdropClick: MouseEventHandler<HTMLDialogElement> = (event) => {
    const dialogDimensions = dialogRef.current?.getBoundingClientRect();
    if (!dialogDimensions) {
      return;
    }

    if (
      event.clientX < dialogDimensions.left ||
      event.clientX > dialogDimensions.right ||
      event.clientY < dialogDimensions.top ||
      event.clientY > dialogDimensions.bottom
    ) {
      dialogRef.current?.close();
      tracker.interaction({
        eventAction: 'overlay|close',
        eventLabel: 'close',
      });
      ga3Tracker.interaction({
        eventAction: 'close',
        eventLabel: 'close',
      });
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
    <dialog
      ref={dialogRef}
      className={`dialog w-md bg-primary mb-auto pt-0 ${styles.disclaimerOverlay}`}
      onClick={onBackdropClick}
      onClose={onClose}
      data-color-mode={overlayColorMode}
    >
      {title && (
        <div
          className={`w-full flex-row h-48 items-center px-48 border-b border-ornament ${styles.disclaimerOverlayHeader}`}
          data-sources={titleSource}
        >
          <h2 className="mr-auto ml-auto font-16 font-medium">{title}</h2>
        </div>
      )}
      <div className="dialog__close">
        <Track
          eventLabel="close"
          eventAction="overlay|close"
          ga3={{ eventAction: 'close' }}
        >
          <IconButton
            aria-label={translate('ReactOverlay.labels.close')}
            bleed
            iconName="navigation-close"
            onClick={onClose}
            variant="clear"
          />
        </Track>
      </div>
      <div className="pt-48">
        <div
          className={`flex-col text-secondary items-start ${styles.disclaimerOverlayContent}`}
        >
          {descriptionTitle && (
            <h2
              className="mb-24 font-24 font-medium"
              data-sources={descriptionTitleSource}
            >
              {descriptionTitle}
            </h2>
          )}
          <Markdown
            markdown={description}
            data-testid={`pop-up-disclaimer:${title}:description`}
            data-sources={descriptionSource}
            className="font-16 font-light"
          />
          {link && link.text && (
            <Track eventLabel="pop-up-disclaimer-read-more">
              <a
                className="button-text text-accent-blue self-center"
                aria-label={link.text}
                href={link.href}
                data-sources={[linkHrefSource, linkTextSource]}
              >
                {link.text}
              </a>
            </Track>
          )}
        </div>
      </div>
    </dialog>
  );
};

export default PopUpDisclaimerOverlay;
