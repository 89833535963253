import { TextStatement } from '@collab/molecules';

import resetMarginsStyle from 'notion/notion.styles';
import { NotionProps } from 'notion/types';

const NotionTextStatement: React.FC<NotionProps> = ({
  block: { title, text },
}) => <TextStatement title={title} text={text} extend={resetMarginsStyle} />;

export default NotionTextStatement;
